<template>
  <div class="pt-4 pb-5">
    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4 d-none d-md-block">Active Calls</h1>
    <h1 class="dark-text font-weight-semi-bold fz-25 mb-4 d-md-none px-5">Active Calls</h1>

    <v-card
      :loading="loading"
      :style="loading ? 'min-height:4px' : ''"
      class="pa-0 overflow-hidden"
    >
      <template v-for="(call, index) in calls.items">
        <v-list
          :key="index"
          width="100%"
          class="py-0"
        >
          <v-col
            cols="12"
            md="12"
            class="pa-0"
            v-if="index"
          >
            <v-divider
              class="hl-724"
            ></v-divider>
          </v-col>

          <v-list-item class="d-flex">
            <v-list-item-avatar
              class="my-3"
            >
              <v-img
                :alt="`Active calls avatar`"
                :src="avatar + '?random=' + index"
              >
                <v-icon
                  color="#fff"
                >
                  phone
                </v-icon>
              </v-img>
            </v-list-item-avatar>

            <v-list-item-content class="py-0 py-md-4" style="max-width: calc(100% - 110px)">
              <v-list-item-title
                class="fz-14 font-weight-medium"
                style="color: #1D2428;"
              >
                <router-link
                  v-if="call.direction === 'in'"
                  class="blue--text text-decoration-none mr-1"
                  :to="hasContact(call) ? {} : { name : 'NewContact', params: {number: call.ani} }"
                >
                  <span v-if="call.caller_first_name || call.caller_last_name">{{call.caller_first_name}} {{call.caller_last_name}}</span>
                  <span v-else>{{call.ani}}</span>
                </router-link>
                <span class="mr-1" v-else>
                  <span v-if="call.caller_first_name || call.caller_last_name">{{call.caller_first_name}} {{call.caller_last_name}}</span>
                  <span v-else>{{call.ani}}</span>
                </span>
                >
                <router-link
                  v-if="call.direction === 'out'"
                  class="blue--text text-decoration-none ml-1"
                  :to="hasContact(call) ? {} : { name : 'NewContact', params: {number: call.dnis} }"
                >
                  <span v-if="call.callee_first_name || call.callee_last_name">{{call.callee_first_name}} {{call.callee_last_name}}</span>
                  <span v-else>{{call.dnis}}</span>
                </router-link>
                <span class="ml-1" v-else>
                  <span v-if="call.callee_first_name || call.callee_last_name">{{call.callee_first_name}} {{call.callee_last_name}}</span>
                  <span v-else>{{call.dnis}}</span>
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <!-- <v-list-item-action
              class="text-right d-flex flex-row no-gutters align-center ma-0"
            >
              <v-btn
                class="grey-button d-none d-md-block"
                @click="callParticipant(callParticipantPopUp = !callParticipantPopUp)"
              >
                <v-icon
                  color="#919EAB"
                  size="20px"
                  class="mr-2"
                >
                  people_alt
                </v-icon>
                Participants
              </v-btn>

              <v-btn
                icon
                class="d-md-none"
                @click="callParticipant(callParticipantPopUp = !callParticipantPopUp)"
              >
                <v-icon
                  color="primary"
                  size="20px"
                >
                  people_alt
                </v-icon>
              </v-btn>
            </v-list-item-action> -->
          </v-list-item>
        </v-list>
      </template>
    </v-card>
    <v-card v-if="!calls.items.length && !loading" class="pa-3 d-flex align-center justify-center"><span>No active calls</span></v-card>
    <v-divider/>
    <div v-if="calls.total > take" class="d-flex justify-space-between">
      <div class="ma-5 br-3 grey-button d-flex">
        <template v-for="index in countPage">
          <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
          <v-btn
            :class="{'grey-button--selected': index === page}"
            text
            tile
            min-width="56"
            class=""
            :key="index"
            @click="pagination(index)"
          >{{ index }}
          </v-btn>
        </template>
      </div>

      <div class="ma-5 br-3 grey-button d-flex">
        <v-btn
          text
          tile
          class="px-0"
          min-width="36"
          width="36px"
          @click="previousPage(page)"
        >
          <v-icon small>
            navigate_before
          </v-icon>
        </v-btn>
        <v-divider vertical/>
        <v-btn
          text
          tile
          min-width="36"
          class="light-text px-0"
          width="36px"
          @click="nextPage(page)"
        >
          <v-icon small>
            navigate_next
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ActiveCalls',
  data: () => ({
    avatar: 'https://picsum.photos/200',
    calls: {
      total: 0,
      items: []
    },
    loading: false,
    page: 1,
    take: 10,
    timer: null
  }),
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts'
    ]),
    callParticipantPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    countPage () {
      return Math.ceil(this.calls.total / this.take)
    }
  },
  watch: {
    page () {
      this.reloadCalls()
    }
  },
  created () {
    this.reloadCalls()
  },
  mounted () {
    this.timer = setInterval(() => {
      this.reloadCalls()
    }, 60000)
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    ...mapActions(['getLiveCalls']),
    async reloadCalls () {
      try {
        this.loading = true

        const { data } = await this.getLiveCalls({
          skip: (this.page - 1) * this.take,
          take: this.take
        })

        this.calls = data.payload
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    hasContact (call) {
      return (call.direction === 'in' && (call.caller_first_name || call.caller_last_name)) ||
      (call.direction === 'out' && (call.callee_first_name || call.callee_last_name))
    },
    getContactName (call) {
      switch (call.direction) {
        case 'in':
          return this.hasContact(call) ? `${call.caller_first_name} ${call.caller_last_name}` : call.ani
        case 'out':
          return this.hasContact(call) ? `${call.callee_first_name} ${call.callee_last_name}` : call.dnis
      }
    },
    callParticipant (payload) {
      this.$store.dispatch('components/RightPopUpMyContacts', payload)
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page > 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    }
  }
}
</script>
