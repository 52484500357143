<template>
  <div class="pt-4">
    <router-link to="/invoice" class="back-link">
      <v-icon size="24px">
        navigate_before
      </v-icon>
      Invoice
    </router-link>

    <h1 class="dark-text font-weight-semi-bold fz-28">Invoice #{{invoice.invoiceNumber}}</h1>

    <div class="d-flex mb-4">
      <v-btn text class="text-none d-flex align-center px-0" height="36px" @click="print">
        <v-icon size="24px" color="#919EAB" class="mr-1">print</v-icon>
        Print
      </v-btn>

      <!-- <v-btn text class="text-none ml-6 d-flex align-center px-0" height="36px">
        <v-icon size="24px" color="#919EAB" class="mr-1">share</v-icon>
        Share
      </v-btn> -->
    </div>

    <invoice-table :invoices="invoice" id="invoice-table" />
  </div>
</template>

<script>
import InvoiceTable from '@/components/InvoiceTable'
export default {
  name: 'Invoice',
  components:
  {
    InvoiceTable
  },
  data: () => ({
    valid: false,
    invoice: {}
  }),
  created () {
    this.init()
  },
  methods: {
    init () {
      if (!this.$route.params.uuid) return
      this.$store.dispatch('getInvoice', this.$route.params.uuid)
        .then((res) => {
          this.invoice = res.data.payload
        }).catch(err => {
          throw new Error(err)
        })
    },
    print () {
      this.$htmlToPaper('invoice-table')
    }
  }
}
</script>
